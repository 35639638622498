import React, { useEffect, useState } from "react";
import "./css/phieu_kham.css";
import {
  FaBriefcaseMedical,
  FaHandHoldingMedical,
  FaHospitalAlt,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { MdOutlineAccessTimeFilled, MdOutlineDateRange } from "react-icons/md";
import { getPhieuKhamDaKham, lichSuKham } from "../api";
import { Image } from "antd";
export default function Da_Kham({ inforUser }) {
  const id_user = localStorage.getItem("idUser");
  const [dataCard, setDataCard] = useState();
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getPhieuKhamDaKham(id_user);
      const res = await lichSuKham(id_user);
      console.log("🚀 ~ fetchData ~ response:", response);
      if (response?.err === 0) {
        setDataCard(response?.Data);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  if (loading) {
    return <p>Đang tải dữ liệu....</p>;
  }
  if (dataCard?.length == 0) {
    return (
      <div className="">
        <Image src={require("../../src/img/icon_searchAnimation.gif")} />
      </div>
    );
  }
  return (
    <div>
      <>
        {dataCard?.map((item, index) => (
          <div key={index} style={{ padding: "20px" }} className="box_profile">
            <div className="userName_User">
              <h2> Tên Bệnh Nhân:{inforUser?.name}</h2>
            </div>

            <div className="line_phieu_kham"></div>
            <div
              style={{
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
              className="infor_user"
            >
              <div className="infor_user_text">
                <p>
                  <span>
                    <FaHospitalAlt />
                  </span>
                  <span
                    style={{
                      color: "rgb(0, 181, 241)",
                      fontWeight: "700",
                      fontSize: "18px",
                      textTransform: "uppercase",
                    }}
                  >
                    {item ? item?.hospital?.name : "Chưa cập nhập"}
                  </span>
                </p>
                <p>
                  <span>
                    <FaBriefcaseMedical />
                  </span>
                  Chuyên khoa :
                  <span>
                    {item?.specialty?.name
                      ? item?.specialty?.name
                      : "Chưa cập nhập"}
                  </span>
                </p>
                <p>
                  <span>
                    <FaHandHoldingMedical />
                  </span>
                  Bác sĩ :
                  <span>
                    {item?.doctor?.name ? item?.doctor?.name : "Chưa cập nhập"}
                  </span>
                </p>
                <p>
                  <span>
                    <MdOutlineDateRange />
                  </span>
                  Ngày Khám :
                  <span>
                    {item?.appointmentDetails?.activateDay
                      ? item?.appointmentDetails?.activateDay
                      : "Chưa cập nhập"}
                  </span>
                </p>
                <p>
                  <span>
                    <MdOutlineAccessTimeFilled />
                  </span>
                  Giờ khám :
                  <span>
                    {item?.appointmentDetails?.timeSlot
                      ? item?.appointmentDetails?.timeSlot
                      : "Chưa cập nhập"}
                  </span>
                </p>
              </div>
              <div className="box_profile_avatar">
                <div
                  className="btn_phieu_kham"
                  style={{ backgroundColor: "#2DAAEF" }}
                >
                  <Link
                    to={`/chi-tiet-phieu-kham/${id_user}`}
                    state={{ dataCard: item, inforUser }}
                  >
                    Đã khám
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    </div>
  );
}
