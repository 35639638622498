import React, { useEffect } from "react";
import { datlich, vnPay_Return } from "../api";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client";
import { Card, Typography, Button } from "antd";
import { formatPrice } from "../Common/dataFortmat";
const { Title, Text } = Typography;
export default function VnPay_return() {
  const searchParams = new URLSearchParams(window.location.search.substring(1));
  const vnpParams = {
    vnp_Amount: searchParams.get("vnp_Amount"),
    vnp_BankCode: searchParams.get("vnp_BankCode"),
    vnp_CardType: searchParams.get("vnp_CardType"),
    vnp_OrderInfo: searchParams.get("vnp_OrderInfo"),
    vnp_PayDate: searchParams.get("vnp_PayDate"),
    vnp_ResponseCode: searchParams.get("vnp_ResponseCode"),
    vnp_TmnCode: searchParams.get("vnp_TmnCode"),
    vnp_TransactionNo: searchParams.get("vnp_TransactionNo"),
    vnp_TransactionStatus: searchParams.get("vnp_TransactionStatus"),
    vnp_TxnRef: searchParams.get("vnp_TxnRef"),
    vnp_SecureHash: searchParams.get("vnp_SecureHash"),
  };
  const scheduleId = localStorage.getItem("scheduleId");
  const idDoctor = localStorage.getItem("idDoctor");
  const id_user = localStorage.getItem("idUser");
  const socket = io("https://medpro.hopitech.xyz");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await vnPay_Return(vnpParams);
        console.log(response);

        // Kiểm tra các trạng thái trả về từ VNPay
        switch (response.code) {
          case "00":
            message.success("Giao dịch thành công");
            const bookingResponse = await datlich(scheduleId, id_user);
            if (bookingResponse?.mess === "Đặt Lịch Thành Công") {
              localStorage.removeItem("scheduleId");
            }

            break;
          case "07":
            message.error("Giao dịch bị nghi ngờ gian lận");
            break;
          case "09":
            message.error(
              "Thẻ/Tài khoản của khách hàng chưa đăng ký dịch vụ InternetBanking tại ngân hàng"
            );
            break;
          case "10":
            message.error(
              "Khách hàng xác thực thông tin thẻ/tài khoản không đúng quá 3 lần"
            );
            break;
          case "11":
            message.error("Đã hết hạn chờ thanh toán");
            break;
          case "12":
            message.error("Thẻ/Tài khoản của khách hàng bị khóa");
            break;
          case "24":
            message.error(
              "Giao dịch không thành công do: Khách hàng hủy giao dịch"
            );
            break;
          default:
            message.error("Giao dịch thất bại");
        }

        if (response.code !== "00") {
          console.log("Hủy lịch đặt");
          socket.emit("cancelScheduleSelection", {
            userId: id_user,
            doctorId: idDoctor,
          });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f0f2f5",
        padding: "20px",
      }}
    >
      <Card style={{ maxWidth: "500px", width: "100%", marginTop: "-30%" }}>
        <Title level={2} style={{ textAlign: "center" }}>
          Thông tin thanh toán VNPay
        </Title>

        <div style={{ marginBottom: "16px" }}>
          <Text strong>Mã giao dịch:</Text>
          <Text>{vnpParams.vnp_TransactionNo}</Text>
        </div>

        <div style={{ marginBottom: "16px" }}>
          <Text strong>Số tiền:</Text>
          <Text>{formatPrice(parseInt(vnpParams.vnp_Amount) / 100)} VNĐ</Text>
        </div>

        <div style={{ marginBottom: "16px" }}>
          <Text strong>Ngân hàng:</Text>
          <Text>{vnpParams.vnp_BankCode}</Text>
        </div>

        <div style={{ marginBottom: "16px" }}>
          <Text strong>Nội dung thanh toán:</Text>
          <Text>{vnpParams.vnp_OrderInfo}</Text>
        </div>

        <div style={{ marginBottom: "16px" }}>
          <Text strong>Thời gian thanh toán:</Text>
          <Text>{vnpParams.vnp_PayDate}</Text>
        </div>

        <div style={{ marginBottom: "16px" }}>
          <Text strong>Trạng thái:</Text>
          <Text
            type={vnpParams.vnp_ResponseCode === "00" ? "success" : "danger"}
          >
            {vnpParams.vnp_ResponseCode === "00"
              ? "Thanh toán thành công"
              : "Thanh toán thất bại"}
          </Text>
        </div>

        <div style={{ textAlign: "center", marginTop: "24px" }}>
          <Button type="primary" onClick={() => (window.location.href = "/")}>
            Quay về trang chủ
          </Button>
        </div>
      </Card>
    </div>
  );
}
