import { ConfigProvider, Tabs } from "antd";
import React from "react";
import Da_Thanh_Toan from "./Da_Thanh_Toan";
import Da_Kham from "./Da_Kham";
import Da_Huy from "./Da_Huy";

export default function Phieu_Kham({ inforUser }) {
  return (
    <div className="main_profile">
      <h2 style={{ marginBottom: "20px" }}>Danh sách phiếu khám bệnh</h2>
      <ConfigProvider
        theme={{
          token: {},
        }}
      >
        <Tabs defaultActiveKey="a">
          <Tabs.Items tab="Đã Thanh Toán" key="a">
            <Da_Thanh_Toan inforUser={inforUser} />
          </Tabs.Items>
          <Tabs.Items tab="Đã Khám" key="c">
            <Da_Kham inforUser={inforUser} />
          </Tabs.Items>
          <Tabs.Items tab="Đã Hủy" key="d">
            <Da_Huy inforUser={inforUser} />
          </Tabs.Items>
        </Tabs>
      </ConfigProvider>
    </div>
  );
}
